import request from "@/utils/request.js"
//获取全部订单
export const getOrderList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/order/list',
      params,
    });
  } 
//获取伊藤全部订单
export const VgetOrderList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/list',
    params,
  });
  } 
//获取报警订单
export const getOrderWarnList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/order/warnList',
    params,
  });
  }
//获取待领取订单
export const getOrderPendingList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/order/pendingList',
      params,
    });
    }
  
  //获取伊藤待领取订单
export const VgetOrderPendingList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/pendingList',
    params,
  });
  }
  
//获取已退款订单
export const getOrderRefundList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/order/refundList',
      params,
    });
    }
  //获取伊藤已退款订单
export const VgetOrderRefundList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/refundList',
    params,
  });
  }
  
//获取已领取订单
export const getOrderReceivedList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/order/receivedList',
      params,
    });
    }
  //获取伊藤已领取订单
export const VgetOrderReceivedList_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/receivedList',
    params,
  });
  }

//获取订单评价
export const getOrderCommen_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/comment',
    params,
  });
  }
  
//回复 
export const doOrderCommentReply_api=params=>{
  return  request({
    method: 'post',
    url: 'api/v2/order/doOrderCommentReply',
    params,
  });
  }
  
//获取订单评价
export const goodRecord_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/order/goodRecord',
    params,
  });
  }
//获取订单出库商品结算
export const VgoodRecord_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/goodRecordSettle',
    params,
  });
  }
  export const getFinancialRecord=params=>{
    return  request({
      method: 'get',
      url: 'api/v2/chain/getFinancialRecord',
      params
    });
  } 