<template>
  <div class="BlackListShopMember" @click="num=0">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>结算管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-form
        ref="form"
        :model="searchForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="订单号">
          <el-input size="small" v-model="searchForm.order_no"/>
        </el-form-item>
        <el-form-item label="账单时间">
          <el-date-picker
            v-model="searchForm.financial_date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="门店名称">
          <el-input size="small" v-model="searchForm.shop_name" style="width:160px;"/>
        </el-form-item>
        <el-form-item label="门店编码">
          <el-input size="small" v-model="searchForm.shop_number" style="width:160px;"/>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchForm.type" style="width:160px;">
            <el-option label="支付" :value="1" />
            <el-option label="退款" :value="2" />
            <el-option label="部分退款" :value="3" />
          </el-select>
        </el-form-item>
        <el-form-item label="打款记录单号" label-width="120px">
          <el-input size="small" v-model="searchForm.payment_no" style="width:160px;"/>
        </el-form-item>
        <el-form-item label="结算记录单号" label-width="120px">
          <el-input size="small" v-model="searchForm.new_settle_no" style="width:160px;"/>
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          style="height: 30px;background: #cd0550; border: none;margin-left: 30px;"
          @click="search"
          >搜索</el-button>
        <el-button
          type="primary"
          size="mini"
          style="height: 30px;background: #E6A23C; border: none;margin-left: 30px;"
          @click="emptySearch"
          >重置</el-button>
          <el-button
          type="primary"
          size="mini"
          style="height: 30px;background: #409EFF; border: none;margin-left: 30px;"
          @click="export1"
          >导出</el-button>
      </el-form>
    </div>
    <div class="data-table">
        <el-table
          ref="table"
          highlight-current-row
          :data="tableData"
          style="width: 100%;overflow: auto;"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="order_no" label="订单号" width="120"/>
          <el-table-column prop="financial_date" label="账单时间(天)" width="120"/>
          <el-table-column prop="shop_name" label="门店名称" width="130" />
          <el-table-column prop="shop_number" label="门店编号"/>
          <el-table-column prop="good_name" label="盒子名称"width="130" />
          <el-table-column prop="good_quantity" label="盒子数量"/>
          <el-table-column prop="order_amount" label="订单金额"/>
          <el-table-column prop="add_love_money" label="录入商品金额" width="120"/>
          <el-table-column prop="order_settle_amount" label="结算金额"/>
          <el-table-column prop="packing_fee" label="打包费"/>
          <el-table-column label="类型" prop="type">
            <template slot-scope="scope">
              <span v-if="scope.row.type==1" style="color: #67C23A;">支付</span>
              <span v-if="scope.row.type==2" style="color: #F56C6C;">退款</span>
              <span v-if="scope.row.type==3" style="color: #E6A23C;">部分退款</span>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="下单时间" width="130"/>
          <el-table-column prop="review_time" label="核销时间" width="130"/>
          <el-table-column prop="refund_time" label="退款时间" width="130"/>
          <el-table-column prop="payment_no" label="打款记录单号" width="130"/>
          <el-table-column prop="new_settle_no" label="结算记录单号" width="130"/>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.page"
            :page-sizes="pageSizes"
            :page-size="pages.limit"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import {getFinancialRecord} from "@/api/order.js"
import { httpUrl } from "@/utils/http.js"
import axios from "axios"

export default {
  data() {
    return {
      filename:'订单记录',
      pageSizes: [10, 20,50],
      total: 0,
      pages:{
        page:1,
        limit:10
      },
      tableData: [],
      searchForm: {
        order_no:null,
        financial_date:[],
        financial_date_start:'',
        financial_date_end:'',
        shop_name:null,
        shop_number:null,
        type:null,
        payment_no:null,
        new_settle_no:null,
      },
    }
  },
  methods: {
    //重置
    emptySearch() {
      this.searchForm.order_no=null
      this.searchForm.financial_date=[]
      this.searchForm.financial_date_start=''
      this.searchForm.financial_date_end=''
      this.searchForm.shop_name=null
      this.searchForm.shop_number=null
      this.searchForm.type=null
      this.searchForm.payment_no=null
      this.searchForm.new_settle_no=null
      this.pages.page=1
      this.init(this.searchForm)
    },
    //搜索
    search() {
      this.pages.page=1
      this.init(this.searchForm)
    },
    //改变条数
    handleSizeChange(e) {
      this.pages.limit = e
      this.init(this.searchForm)
    },
    //切换页
    handleCurrentChange(e) {
      this.pages.page = e
      this.init(this.searchForm)
    },
    //获取列表
   async init(searchForm){
      if(searchForm.financial_date.length>0){
        searchForm.financial_date_start=searchForm.financial_date[0]
        searchForm.financial_date_end=searchForm.financial_date[1]
      }
      let res=await getFinancialRecord({...searchForm,...this.pages})
      this.tableData=res.data.data.list || []
      this.total=res.data.data.total_count
    },
    export1() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      if(this.searchForm.financial_date.length>0){
        var financial_date_start=this.searchForm.financial_date[0]
        var financial_date_end=this.searchForm.financial_date[1]
      }else{
        var financial_date_start=''
        var financial_date_end=''
      }
      const order_no=this.searchForm.order_no?this.searchForm.order_no:''
      const shop_name=this.searchForm.shop_name?this.searchForm.shop_name:''
      const shop_number=this.searchForm.shop_number?this.searchForm.shop_number:''
      const type=this.searchForm.type?this.searchForm.type:''
      const payment_no=this.searchForm.payment_no?this.searchForm.payment_no:''
      const new_settle_no=this.searchForm.new_settle_no?this.searchForm.new_settle_no:''
      axios
        .get(
          `${httpUrl}api/v2/chain/getFinancialRecordExport?order_no=${order_no}&financial_date_start=${financial_date_start}&financial_date_end=${financial_date_end}&shop_name=${shop_name}&shop_number=${shop_number}&type=${type}&payment_no=${payment_no}&new_settle_no=${new_settle_no}`,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
    exportTestTime() {
      const loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data={
        order_no:this.searchForm.order_no?this.searchForm.order_no:'',
        financial_date:this.searchForm.financial_date,
        financial_date_start:this.searchForm.financial_date.length>0?this.searchForm.financial_date[0]:'',
        financial_date_end:this.searchForm.financial_date.length>0?this.searchForm.financial_date[1]:'',
        shop_name:this.searchForm.shop_name?this.searchForm.shop_name:'',
        shop_number:this.searchForm.shop_number?this.searchForm.shop_number:'',
        type:this.searchForm.type?this.searchForm.type:'',
        payment_no:this.searchForm.payment_no?this.searchForm.payment_no:'',
        new_settle_no:this.searchForm.new_settle_no?this.searchForm.new_settle_no:''
      }
        axios({
          method:'get',
          url:`${httpUrl}api/v2/chain/getFinancialRecordExport`,
          params:data,
          responseType:'blob'
        })
        .then((res) => {
          if (!res) return;
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.filename; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    }
  },
  created() {
    this.init(this.searchForm)
  }
}
</script>

<style lang="less" scoped>
.BlackListShopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
// 分页
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
// 表格
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  width: 100%;
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: auto;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  z-index: 0;
}
</style>